const searchBtn = document.getElementById("searchBtn");
const searchVal = document.querySelector("input");
const spantitle = document.getElementById("title");
const poster = document.getElementById("poster");
const infolist = document.getElementById("infolist");
let changemode = document.querySelector("#changemode");
const apikey = "5a76bde5";

async function fetchMovie(str) {
  const url = `https://www.omdbapi.com/?apikey=${apikey}&t=${str}}`;
  let obj = {
    movie: "",
  };

  fetch(url)
    .then((res) => res.json())
    .then((data) => {
      obj.movie = data;
      // console.log(obj.movie);
      poster.innerHTML = `<img src="${obj.movie.Poster}" alt="">`;
      spantitle.textContent = obj.movie.Title;
      infolist.innerHTML = `
      <p>Box Office: ${obj.movie.BoxOffice}</p>
      <p>Released: ${obj.movie.Released}</p>
      <p>Runtime: ${obj.movie.Runtime}</p>
      <p>Rated: ${obj.movie.Rated}</p>
      <p>Genre: ${obj.movie.Genre}</p>
      <p>Director: ${obj.movie.Director}</p>
      <p>Writer: ${obj.movie.Writer}</p>
      <p>Plot: ${obj.movie.Plot}</p>
      <p>Actors: ${obj.movie.Actors}</p>
      <p>Awards: ${obj.movie.Awards}</p>
        `;
    });
}

const fave = [
  "Iron Man",
  "The Incredible Hulk",
  "Iron Man 2",
  "Thor",
  "Captain America: The First Avenger",
  "The Avengers",
  "Iron Man 3",
  "Thor: The Dark World",
  "Captain America: The Winter Soldier",
  "Guardians of the Galaxy",
  "Avengers: Age of Ultron",
  "Ant-Man",
  "Captain America: Civil War",
  "Doctor Strange",
  "Guardians of the Galaxy 2",
  "Spider-Man: Homecoming",
  "Thor: Ragnarok",
  "Black Panther",
  "Avengers: Infinity War",
  "Ant-Man and the Wasp",
  "Captain Marvel",
  "Avengers: Endgame",
  "Spider-Man: Far From Home",
  "WandaVision",
  "Falcon and the Winter Soldier",
  "Loki",
  "Black Widow",
  "What If...?",
  "Shang-Chi and the Legend of the Ten Rings",
  "Eternals",
  "Hawkeye",
  "Spider-Man: No Way Home",
  "Moon Knight",
  "Doctor Strange in the Multiverse of Madness",
  "Ms. Marvel",
  "Thor: Love and Thunder",
  "I Am Groot",
  "She-Hulk",
  "Werewolf By Night",
  "Black Panther: Wakanda Forever",
  "Ant-Man and the Wasp: Quantumania",
];
document.addEventListener(
  "DOMContentLoaded",
  fetchMovie(fave[Math.floor(Math.random() * 41)])
);

searchBtn.addEventListener("click", () => {
  poster.innerHTML = "";
  const movietitle = searchVal.value;
  fetchMovie(movietitle);
  searchVal.value = "";
});

window.addEventListener("keypress", (e) => {
  if (!searchVal.value) {
    return;
  } else {
    console.log(e);
    if (e.key === "Enter") {
      poster.innerHTML = "";
      const movietitle = searchVal.value;
      fetchMovie(movietitle);
      searchVal.value = "";
    }
  }
});

window.onload = function () {
  searchVal.focus();
};

function change(e) {
  if (e.target.classList.toggle("bi-moon")) {
    document.body.classList.remove("dark");
    document.body.classList.add("light");
    document.body.style.transition = '2s';
  } else {
    document.body.classList.remove("light");
    document.body.classList.add("dark");
    document.body.style.transition = '2s';
  }
}
changemode.addEventListener("click", change);
